// import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
// import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
// import GUI from "lil-gui";

// /**
//  * Base
//  */
// // Debug
// const gui = new GUI();

// // Canvas
// const canvas = document.querySelector("canvas.webgl");

// // Scene
// const scene = new THREE.Scene();

// // const axesHelper = new THREE.AxesHelper();
// // scene.add(axesHelper);

// /**
//  * Textures
//  */
// const textureLoader = new THREE.TextureLoader();
// const matcapTexture = textureLoader.load("/textures/matcaps/8.png");

// const fontloader = new FontLoader();

// fontloader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
//   const textGeometry = new TextGeometry("Vishal Kr. Singh", {
//     font,
//     size: 0.5,
//     height: 0.2,
//     curveSegments: 4,
//     bevelEnabled: true,
//     bevelThickness: 0.03,
//     bevelSize: 0.02,
//     bevelOffset: 0,
//     bevelSegments: 5,
//   });

//   textGeometry.center();

//   //   textGeometry.computeBoundingBox();
//   //   textGeometry.translate(
//   //     -(textGeometry.boundingBox.max.x - 0.02) * 0.5,
//   //     -(textGeometry.boundingBox.max.y - 0.02) * 0.5,
//   //     -(textGeometry.boundingBox.max.z - 0.03) * 0.5
//   //   );

//   const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
//   //   textMaterial.wireframe = true;
//   const text = new THREE.Mesh(textGeometry, material);
//   scene.add(text);

//   const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);

//   //   const cubeGeometry = new THREE.ConeGeometry(0.5, -0.5, 0.5);

//   for (let i = 0; i < 200; i++) {
//     const donut = new THREE.Mesh(donutGeometry, material);
//     // const box = new THREE.Mesh(cubeGeometry, material);

//     donut.position.x = (Math.random() - 0.5) * 10;
//     donut.position.y = (Math.random() - 0.5) * 10;
//     donut.position.z = (Math.random() - 0.5) * 10;

//     donut.rotation.x = Math.random() * Math.PI;
//     donut.rotation.y = Math.random() * Math.PI;

//     const scale = Math.random();
//     donut.scale.set(scale, scale, scale);

//     // for box :
//     // box.position.x = (Math.random() - 0.5) * 10;
//     // box.position.y = (Math.random() - 0.5) * 10;
//     // box.position.z = (Math.random() - 0.5) * 10;

//     // box.rotation.x = Math.random() * Math.PI;
//     // box.rotation.y = Math.random() * Math.PI;

//     // box.scale.set(scale, scale, scale);

//     scene.add(donut);
//     // scene.add(box);
//   }
// });

// /**
//  * Sizes
//  */
// const sizes = {
//   width: window.innerWidth,
//   height: window.innerHeight,
// };

// window.addEventListener("resize", () => {
//   // Update sizes
//   sizes.width = window.innerWidth;
//   sizes.height = window.innerHeight;

//   // Update camera
//   camera.aspect = sizes.width / sizes.height;
//   camera.updateProjectionMatrix();

//   // Update renderer
//   renderer.setSize(sizes.width, sizes.height);
//   renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
// });

// /**
//  * Camera
//  */
// // Base camera
// const camera = new THREE.PerspectiveCamera(
//   75,
//   sizes.width / sizes.height,
//   0.1,
//   100
// );
// camera.position.x = 1;
// camera.position.y = 1;
// camera.position.z = 2;
// scene.add(camera);

// // Controls
// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;

// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//   canvas: canvas,
// });
// renderer.setSize(sizes.width, sizes.height);
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// /**
//  * Animate
//  */
// const clock = new THREE.Clock();

// const tick = () => {
//   const elapsedTime = clock.getElapsedTime();

//   // Update controls
//   controls.update();

//   // Render
//   renderer.render(scene, camera);

//   // Call tick again on the next frame
//   window.requestAnimationFrame(tick);
// };

// tick();

/////////////////////////////////////////////////////////////

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { ARButton } from "three/examples/jsm/webxr/ARButton.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import GUI from "lil-gui";

/**
 * Base
 */
// Debug
const gui = new GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load("/textures/matcaps/8.png");

const fontloader = new FontLoader();

fontloader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
  const textGeometry = new TextGeometry("Vishal Kr. Singh", {
    font,
    size: 0.5,
    height: 0.2,
    curveSegments: 4,
    bevelEnabled: true,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  });

  textGeometry.center();

  const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
  const text = new THREE.Mesh(textGeometry, material);
  scene.add(text);

  const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);

  for (let i = 0; i < 200; i++) {
    const donut = new THREE.Mesh(donutGeometry, material);

    donut.position.x = (Math.random() - 0.5) * 10;
    donut.position.y = (Math.random() - 0.5) * 10;
    donut.position.z = (Math.random() - 0.5) * 10;

    donut.rotation.x = Math.random() * Math.PI;
    donut.rotation.y = Math.random() * Math.PI;

    const scale = Math.random();
    donut.scale.set(scale, scale, scale);

    scene.add(donut);
  }
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(1, 1, 2);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.xr.enabled = false;

/**
 * Create AR button
 */
const arButton = document.createElement("button");
arButton.style.position = "absolute";
arButton.style.top = "10px";
arButton.style.left = "10px";
arButton.style.background = "#000";
arButton.style.color = "#fff";
arButton.style.fontSize = "16px";
arButton.style.padding = "8px 16px";
arButton.style.border = "none";
arButton.style.cursor = "pointer";
arButton.textContent = "Enter AR";
document.body.appendChild(arButton);

arButton.addEventListener("click", () => {
  // Request camera permissions
  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((stream) => {
      // If AR is supported, enter AR mode
      if (renderer.xr.enabled) {
        renderer.xr.setSession(
          navigator.xr.requestSession("immersive-ar", {
            requiredFeatures: ["hit-test"],
          })
        );
      } else {
        // If AR is not supported, show an alert with the error
        alert("AR is not supported on this device.");
      }
    })
    .catch((error) => {
      // Handle permission errors
      alert("Error accessing camera: " + error.message);
    });
});

/**
 * Check for AR capabilities
 */
if (navigator.xr) {
  navigator.xr
    .isSessionSupported("immersive-ar")
    .then((supported) => {
      console.log("AR support:", supported); // Debugging log
      if (supported) {
        renderer.xr.enabled = true;
      }
    })
    .catch((error) => {
      console.error("Error checking AR support:", error); // Debugging log
      arButton.textContent = "AR not supported";
    });
} else {
  console.log("WebXR not supported"); // Debugging log
  arButton.textContent = "AR not supported";
}

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

// Start the animation loop
tick();
